import IW from "../../../../src/components/inline-word/InlineWord";
import Word from "../../../../src/components/word/Word";
import TensesChart from "../../../../src/components/tenses-chart/TensesChart";
import pakhawulDiagram from "../../../../content/blog/pashto-compound-verbs/cooking-potatoes.svg";
import becomingCookedDiagram from "../../../../content/blog/pashto-compound-verbs/potatoes-cooking.svg";
import kaarKawulDiagram from "../../../../content/blog/pashto-compound-verbs/doing-work.svg";
import stativeCompoundTransitive from "../../../../content/blog/pashto-compound-verbs/stative-compound-transitive.svg";
import stativeCompoundIntransitive from "../../../../content/blog/pashto-compound-verbs/stative-compound-intransitive.svg";
import dynamicCompoundTransitive from "../../../../content/blog/pashto-compound-verbs/dynamic-compound-transitive.svg";
import dynamicCompoundInransitive from "../../../../content/blog/pashto-compound-verbs/dynamic-compound-intransitive.svg";
import khufaKawulDiagram from "../../../../content/blog/pashto-compound-verbs/making-sad.svg";
import becomingSadDiagram from "../../../../content/blog/pashto-compound-verbs/becoming-sad.svg";
import makingTiredDiagram from "../../../../content/blog/pashto-compound-verbs/making-tired.svg";
import becomingTiredDiagram from "../../../../content/blog/pashto-compound-verbs/becoming-tired.svg";
import closingDoorDiagram from "../../../../content/blog/pashto-compound-verbs/closing-door.svg";
import doorClosingDiagram from "../../../../content/blog/pashto-compound-verbs/door-closing.svg";
import explainingStoryDiagram from "../../../../content/blog/pashto-compound-verbs/explaining-story.svg";
import storyExplainedDiagram from "../../../../content/blog/pashto-compound-verbs/story-explained.svg";
import storyExplained from "../../../../content/blog/pashto-compound-verbs/story-explained.svg";
import runningDiagram from "../../../../content/blog/pashto-compound-verbs/running.svg";
import stativeIroningDiagram from "../../../../content/blog/pashto-compound-verbs/ironing-stative.svg";
import dynamicIroningDiagram from "../../../../content/blog/pashto-compound-verbs/ironing-dynamic.svg";
import { Link } from "gatsby";
import { getVerbInfo, RootsAndStems } from "@lingdocs/pashto-inflector";
import * as React from 'react';
export default {
  IW,
  Word,
  TensesChart,
  pakhawulDiagram,
  becomingCookedDiagram,
  kaarKawulDiagram,
  stativeCompoundTransitive,
  stativeCompoundIntransitive,
  dynamicCompoundTransitive,
  dynamicCompoundInransitive,
  khufaKawulDiagram,
  becomingSadDiagram,
  makingTiredDiagram,
  becomingTiredDiagram,
  closingDoorDiagram,
  doorClosingDiagram,
  explainingStoryDiagram,
  storyExplainedDiagram,
  storyExplained,
  runningDiagram,
  stativeIroningDiagram,
  dynamicIroningDiagram,
  Link,
  getVerbInfo,
  RootsAndStems,
  React
};