import TensesChart from "../../../../src/components/tenses-chart/TensesChart";
import Example from "../../../../src/components/example/Example";
import VideoPlayer from "../../../../src/components/VideoPlayer";
import { getVerbInfo, RootsAndStems, defaultTextOptions } from "@lingdocs/pashto-inflector";
import imperfInTime from "../../../../content/blog/pashto-verbs-master-chart/imperfective-time.svg";
import presentImg from "../../../../content/blog/pashto-verbs-master-chart/present.svg";
import subjunctiveImg from "../../../../content/blog/pashto-verbs-master-chart/subjunctive.svg";
import perfFutureImg from "../../../../content/blog/pashto-verbs-master-chart/perfective-future.svg";
import imperfFutureImg from "../../../../content/blog/pashto-verbs-master-chart/imperfective-future.svg";
import perfImperativeImg from "../../../../content/blog/pashto-verbs-master-chart/perfective-imperative.svg";
import imperfImperativeImg from "../../../../content/blog/pashto-verbs-master-chart/imperfective-imperative.svg";
import perfPast from "../../../../content/blog/pashto-verbs-master-chart/perfective-past.svg";
import imperfPast from "../../../../content/blog/pashto-verbs-master-chart/imperfective-past.svg";
import presentPerfect from "../../../../content/blog/pashto-verbs-master-chart/present-perfect.svg";
import imperfectiveHabitualPast from "../../../../content/blog/pashto-verbs-master-chart/imperfective-habitual-past.svg";
import perfectiveHabitualPast from "../../../../content/blog/pashto-verbs-master-chart/perfective-habitual-past.svg";
import pastPerfect from "../../../../content/blog/pashto-verbs-master-chart/past-perfect.svg";
import futurePerfect from "../../../../content/blog/pashto-verbs-master-chart/future-perfect.svg";
import affirmationalPerfect from "../../../../content/blog/pashto-verbs-master-chart/affirmational-perfect.svg";
import * as React from 'react';
export default {
  TensesChart,
  Example,
  VideoPlayer,
  getVerbInfo,
  RootsAndStems,
  defaultTextOptions,
  imperfInTime,
  presentImg,
  subjunctiveImg,
  perfFutureImg,
  imperfFutureImg,
  perfImperativeImg,
  imperfImperativeImg,
  perfPast,
  imperfPast,
  presentPerfect,
  imperfectiveHabitualPast,
  perfectiveHabitualPast,
  pastPerfect,
  futurePerfect,
  affirmationalPerfect,
  React
};