module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":590}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":"Table of Contents","tight":false,"fromHeading":1,"toHeading":6,"className":"table-of-contents"}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":"<i class=\"fas fa-link mr-1\"></i>"}},"gatsby-plugin-postcss","gatsby-remark-prismjs","gatsby-remark-copy-linked-files","gatsby-remark-smartypants"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LingDocs","short_name":"LingDocs","start_url":"/","background_color":"#ffffff","theme_color":"#808387","display":"minimal-ui","icon":"content/assets/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c06dfdd6ff5545d8f84056b412808b57"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FNB8658Z2J"],"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
